<template lang="pug">
  BaseSection
    .card.mt-2
      .card-body
        h1 Privacy policy
        p As the operators of the Refair.me website, TechSorted Sp.Zo.. registered in Poland with Company Registration Number 0000496061("We", "Us", “RefairMe/ TechSorted”) is committed to protecting and respecting your privacy. This Privacy and Cookies Policy ("Policy") relates to services provided through our website and application (“Services”) and sets out the basis on which the personal data collected from you, or that you provide to Us will be processed by Us. Please read the following carefully to understand our views and practices regarding your personal data and how We will treat it. For the purpose of, the General Data Protection Regulation (“GDPR”), from the GDPR implementation date or, until GDPR implementation date, the Data Protection Act 1998 (collectively the “Data Protection Laws”):
        ul
          li in respect of the personal data of users of the Website and the Services and business contacts and prospects of TechSorted/ RefairMe’s, the Data Controller is TechSorted Limited;
          li In respect of the personal data of candidates who apply for, or who a Customer of TechSorted contacts in respect of, an Opening (as the term is described in TechSorted’s Terms) (“Candidates”) TechSorted/ RefairMe’s shall process personal information as a data processor on behalf of its Customers, who use Our Services to assist with their recruitment processes . Where you apply for a role with one of TechSorted/ RefairMe’s Customers, our Customer’s privacy policy, rather than this Privacy Policy, will apply to our processing of your personal information.
        h1 Information we collect from you
        p We collect and process some or all of the following types of information from you:
        ul
          li Information that you provide by filling in forms on the TechSorted/ RefairMe’s.com website (“Website”)or App. This includes information provided at the time of registering to use the Website, subscribing to our Services, posting material or requesting further information or services. We may also ask you for information when you report a problem with the Website.
          li If you contact Us, We may keep a record of that correspondence.
          li We may also ask you to complete surveys that We use for research purposes, although you do not have to respond to them.
          li Details of all actions that you carry out through the Website ,App and of the provision of services to you.
          li Details of your visits to the Website and App including, but not limited to, traffic data, location data, weblogs and other communication data, the site that referred you to our site and the resources that you access.
        p The provision of your full name and e-mail address, your employer and/or your place of work and the url of the business that you work for is required from you when you register to use our Services We will inform you at the point of collecting information from you, whether you are required to provide the information to Us.
        h1 Information we collect from other sources
        p From time to time we also obtain personal data from other sources as follows:
        ul
          li names and contact details of Customer personnel who will be added as account members for the Customer’s account, may be added by existing account members;
          li names and contact details of individual contacts at prospective Customers from third party data providers and/or public sources, such as social networks, company websites and other online sources.
        h1 Uses made of your information
        p Where you are using our Services on behalf of our Customer, we rely on legitimate interests in performing our contract with our Customer as the lawful basis on which We collect and use your personal data. We use information held about you in the following ways:
        ul
          li To ensure that content from the Website is presented in the most effective manner for you and for your computer.
          li To provide you with information, products or services that you request from us or which we feel may interest you or our Customer.
          li To carry out our obligations arising from any contracts entered into between our Customer (on whose behalf you are using the Services) and Us.
          li To notify you about changes to our Services and provide you with information that is relevant to your use of the Services.
          li Where you or your employer are a prospective Customer, to provide you with information about our Services for marketing purposes.
        h1 Disclosure of your information
        p We may disclose your personal information to any member of our group, which means our subsidiaries, our ultimate holding company and its subsidiaries, as defined in section 1159 of the UK Companies Act 2006 (where applicable). Where any such member of our group is outside the EU this transfer will be on the basis of a contract including the Model Contractual Clauses in accordance with the Data Protection Laws.
        p We may disclose your personal information to third parties:
        ul
          li in the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller or buyer of such business or assets;
          li if we or substantially all of our assets are acquired by a third party, in which case personal data held by us about our customers will be one of the transferred assets;
          li if we are under a duty to disclose or share your personal data in order to comply with any legal obligation or in order to enforce or apply our Website Terms and Conditions and other agreements, but we will endeavour to minimise such disclosure to only that reasonably necessary and, where possible, to provide you with notice of such disclosure; and/or
          li to protect the rights, property, or safety of TechSorted/ RefairMe’s Technology Limited, the Website, our users and any third party we interact with to provide the Website.
        h1 How we store your personal data
        h3 Security
        p We take appropriate measures to ensure that all personal data is kept secure including security measures to prevent personal data from being accidentally lost, or used or accessed in an unauthorised way, for the duration of your use of our Services. We limit access to your personal data to those who have a genuine business need to know it. Those processing your information will do so only in an authorised manner and are subject to a duty of confidentiality.
        p We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where We are legally required to do so.
        p Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to the Website, therefore any transmission remains at your own risk. Once we have received your information, we will use strict procedures and security features in order to prevent unauthorised access.
        h3 Keeping your personal data up to date
        p If your personal details change you may update them by accessing the relevant page of the Website, or by contacting Us using the contact details below.
        p We will endeavour to update your personal data within thirty (30) days of any new or updated personal data being provided to Us, in order to ensure that the personal data We hold about you is as accurate and up to date as possible.
        h3 Where we store your personal data
        p The data that We collect from you and process as a result of your use of the Services may be transferred to, and stored at, a destination outside the European Economic Area ("EEA"). It may also be processed by staff operating outside the EEA who work for Us or for one of our suppliers. Such staff maybe engaged in, among other things, the fulfilment of your orders, the processing of your payment details and the provision of support services. By submitting your personal data, you agree to this transfer, storing or processing.
        p In particular, your data may be accessible to i) TechSorted/ RefairMe’s’s staff in the USA or ii) may be stored by TechSorted/ RefairMe’s’s hosting service provider on servers in the USA as well as in the EU. The USA does not have the same data protection laws as the United Kingdom and EEA. A Data Processor Agreement has been signed between TechSorted/ RefairMe’s Software Limited and TechSorted/ RefairMe’s Technology Limited and their overseas group companies, and between TechSorted/ RefairMe’s Software Limited and TechSorted/ RefairMe’s Technology Limited and each of its data processors. These Data Processor Agreements that are designed to help safeguard your privacy rights and give you remedies in the unlikely event of a misuse of your personal data. A full list of TechSorted/ RefairMe’s's sub-processors can be found below:
        b-table(bordered hover :items="items")
        p If you would like further information please contact Us (see ‘Contact’ below). We will not otherwise transfer your personal data outside of the United Kingdom OR EEA or to any organisation (or subordinate bodies) governed by public international law or which is set up under any agreement between two or more countries.
        h3 How long we keep your personal data
        p We will hold all the data for so long as we have an obligation to the Customer to provide the Services, and thereafter until such time as we delete the Customer’s account in accordance with our Customer Terms and Conditions.
        p Your personal information will be deleted on one of the following occurrences:
        ul
          li deletion of your personal information by you (or by another person engaged by the Customer); or
          li receipt of a written request by you (or another person engaged by the Customer) to us.
        h1 Your rights
        p Under the 
          a(href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&from=EN") General Data Protection Regulation
          |  you have a number of important rights free of charge. In summary, those include rights to:
        ul
          li access to your personal data and to certain other supplementary information that this Policy is already designed to address
          li require Us to correct any mistakes in your information which We hold
          li require the erasure of personal data concerning you in certain situations
          li receive the personal data concerning you which you have provided to Us, in a structured, commonly used and machine-readable format and have the right to transmit those data to a third party in certain situations
          li object at any time to processing of personal data concerning you for direct marketing
          li object to decisions being taken by automated means which produce legal effects concerning you or similarly significantly affect you
          li object in certain other situations to our continued processing of your personal data
          li otherwise restrict our processing of your personal data in certain circumstances
          li claim compensation for damages caused by our breach of any data protection laws.
        p If you would like to exercise any of those rights, please:
        ul
          li contact us using our Contact details below
          li let Us have enough information to identify you,
          li let Us have proof of your identity and address. Where you are a user of our Services you should email us from the email address that you used to register with TechSorted/ RefairMe’s. Receipt of an email from this address will usually be sufficient to confirm your identity. In all other cases we may request one or more identification documents, such as a copy of your driving licence or passport and a recent utility or credit card bill; and
          li let Us know the information to which your request relates.
        h1 Third Party Websites
        p The Website may, from time to time, contain links to and from the websites of our partner networks, advertisers and affiliates. If you follow a link to any of these websites, please note that these websites have their own privacy policies and terms of use and that we do not accept any responsibility or liability for these policies and terms of use. Please check these policies before you submit any personal data to these websites.
        h1 How to complain
        p We hope that We can resolve any query or concern you raise about our use of your information.
        p The 
          a(href="https://eur-lex.europa.eu/legal-content/EN/TXT/PDF/?uri=CELEX:32016R0679&from=EN") General Data Protection Regulation
          |  also gives you right to lodge a complaint with a supervisory authority, in particular in the European Union (or European Economic Area) state where you work, normally live or where any alleged infringement of data protection laws occurred.
        h1 Changes to our privacy policy
        p We reserve the right to modify this Privacy Policy at any time. Any changes we may make to our Policy in the future will be notified and made available to you using the Website. Your continued use of the Services and the Website shall be deemed your acceptance of the varied Privacy Policy.
        h1 IP Addresses and cookies
        p We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration, customer support and to collect aggregate information for internal reporting purposes.
        p In addition, our Website uses cookies. A cookie is a small file of letters and numbers that we put on your computer if you agree. These cookies allow us to distinguish you from other users of the Website, which helps us to provide you with a good experience when you browse our Website and also allows us to improve the Website.
        p The cookies we use are "analytical" cookies. Some of the common uses for our cookies are as follows:
        ul
          li to recognise and count the number of visitors and to see how visitors move around the site when they are using it. This helps us to improve the way our Website works, for example by ensuring that users are finding what they are looking for easily.
          li to identify and authenticate a user across different pages of our Website, within our own Website, in a session or across different sessions. This is so that the user does not need to provide a password on every page the user visits; and
          li to be able to retrieve a user’s previously stored data, for example, information that the user previously submitted to the Website, so as to facilitate reuse of this information by the user.
        h1 Contact
        p All questions, comments and requests regarding this Privacy Policy should be addressed to 
          a(href="mailto:support@RefairMe.com") support@RefairMe.com


</template>

<script>
import { BaseSection } from '@/components'

export default {
    name: 'PrivacyPolicy',

    components: { BaseSection },

    data() {
        return {
            items: [
                {
                    name_of_sub_processor: 'Amazon Web Services, Inc.',
                    address_of_sub_processor: '410 Terry Ave North Seattle, WA 98109-5210',
                },
                {
                    name_of_sub_processor: 'Google Cloud Platform',
                    address_of_sub_processor:
                        'Google Ireland Limited,\nGordon House, Barrow Street, Dublin 4, Ireland',
                },
            ],
        }
    },
}
</script>
